import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import IconPlus from '../IconPlus/IconPlus';

import css from './SelectSingleFilterPlain.module.css';
import { InlineTextButton } from '../../../components';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const OptionMarkdown = ({
  initialValue,
  isFirst,
  option,
  selectOption,
  useBorder,
  useHighlight,
}) => {
  // check if this option is selected
  const selected = initialValue === option.key;
  const optionClass = classNames(css.option, {
    [css.optionHighlight]: selected && useHighlight,
  });

  // menu item selected bullet or border class
  const optionBorderClass = useBorder
    ? classNames({
        [css.optionBorderSelected]: selected,
        [css.optionBorder]: !selected,
      })
    : null;

  return (
    <button
      className={optionClass}
      key={option.key}
      onClick={() => selectOption(option.key)}
      style={{ paddingTop: `${isFirst ? '8px' : '12px'}` }}
    >
      {useBorder ? <span className={optionBorderClass} /> : null}
      {option.label}
    </button>
  );
};

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isExpanded: false,
      filteredSearchOptions: props.options || [],
      searchValue: '',
    };
    this.options = props.options || [];
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  filterSearchOptions(event) {
    const value = event.target.value;
    this.setState({
      filteredSearchOptions: this.options.filter(option => {
        if (typeof option === 'object' && Object.hasOwnProperty.call(option, 'key')) {
          return option.key
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        } else {
          return option
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }
      }),
      searchValue: value,
    });
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      useBorder,
      useHighlight,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.labelSelected : css.label;

    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>{label}</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={!!initialValue} />
              </span>
            </span>
          </button>
        </div>

        <div className={optionsContainerClass}>
          {this.options.length > 5 ? (
            <input
              className={css.optionsFilter}
              onChange={event => this.filterSearchOptions(event)}
              placeholder={`Search ${
                label.charAt(label.length - 1).toLowerCase() === 'y'
                  ? `${label.toLowerCase().slice(0, -1)}ies`
                  : `${label.toLowerCase()}s`
              }`}
              type="text"
              value={this.state.searchValue}
            />
          ) : null}

          {this.state.filteredSearchOptions.length <= 0 ? (
            <p className={css.optionsSearchError}>
              No {label.toLowerCase()}s matching <i>"{this.state.searchValue}"</i>
            </p>
          ) : (
            <>
              <div
                className={css.optionsScrollContainer}
                style={{
                  maxHeight: '220px',
                  overflowY: 'scroll',
                }}
              >
                {this.state.filteredSearchOptions.map((option, key) => (
                  <OptionMarkdown
                    key={key}
                    initialValue={initialValue}
                    isFirst={key === 0}
                    option={option}
                    selectOption={this.selectOption}
                    useBorder={useBorder}
                    useHighlight={useHighlight}
                  />
                ))}
              </div>
            </>
          )}
          <button className={css.clearButton} onClick={e => this.selectOption(null, e)}>
            <FormattedMessage id={'SelectSingleFilter.plainClear'} />
          </button>
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default SelectSingleFilterPlain;
